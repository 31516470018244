import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  EXLY_GLOBAL__SET_SHOW_BOTTOM_NAV,
  EXLY_GLOBAL__SET_SHOW_APP_BAR,
  EXLY_GLOBAL__SET_PAGE_TITLE,
  EXLY_GLOBAL__CUSTOM_BACK_NAVIGATION,
} from "redux/actions";
import useDesktopMediaQuery from "./useDesktopMediaQuery";

/**
 * @depricated please use useAppLayoutControl
 * @param {*} title
 * @returns
 */
const useHideNavAndAppBar = (title, customBackAction = undefined) => {
  const dispatch = useDispatch();
  const is_desktop = useDesktopMediaQuery();

  useEffect(() => {
    // setting boolean values to show/hide bottom nav & app bar
    dispatch({ type: EXLY_GLOBAL__SET_SHOW_BOTTOM_NAV, payload: false });
    dispatch({ type: EXLY_GLOBAL__SET_SHOW_APP_BAR, payload: is_desktop });

    // reset values on unmount
    return () => {
      dispatch({ type: EXLY_GLOBAL__SET_SHOW_BOTTOM_NAV, payload: true });
      dispatch({ type: EXLY_GLOBAL__SET_SHOW_APP_BAR, payload: true });
      dispatch({ type: EXLY_GLOBAL__CUSTOM_BACK_NAVIGATION, payload: null });
    };
  }, [is_desktop]);

  useEffect(() => {
    if (title) {
      dispatch({
        type: EXLY_GLOBAL__SET_PAGE_TITLE,
        payload: title,
      });
    }
    if (customBackAction) {
      dispatch({
        type: EXLY_GLOBAL__CUSTOM_BACK_NAVIGATION,
        payload: customBackAction,
      });
    }
  }, [title]);

  return null;
};

export default useHideNavAndAppBar;
