import { useState, useCallback } from "react";
import debounce from "lodash/debounce";

function useDebounce({ inititalValue, delay }) {
  const [value, setValue] = useState(inititalValue);
  const [debouncedValue, setDebouncedValue] = useState(value);

  const handleDebouncedChange = useCallback(
    debounce(setDebouncedValue, [delay]),
    [delay]
  );

  function handleSetValue(value) {
    setValue(value);
    handleDebouncedChange(value);
  }

  return {
    value,
    setValue: handleSetValue,
    debouncedValue,
    handleDebouncedChange,
  };
}

export default useDebounce;
